import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteSwayForm, getSwayForms } from "../../actions/patientActions";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export const SwayFormDeleteConfirmModal = ({
  show,
  handleClose,
  id,
  token,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const deleteSway = () => {
    setLoading(true);
    dispatch(deleteSwayForm(token, id))
      .then((response) => {
        toast.success("Sway Form deleted successfully");
        setLoading(false);
        dispatch(getSwayForms(token))
          .then((response) => {})
          .catch((error) => {
            history.push("/sway-forms");
          });
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting Sway Form");
      });
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title id="contained-modal-title-vcenter">
            Sway Form Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this sway form?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteSway} disabled={loading}>
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
