import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const MedicalHistory2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    medications: [],
    nameInput: "",
    doseInput: "",
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm6"));
  const initialValues = id
    ? { ...defaultValues, medications: concussionForm.medications || [] }
    : {
        ...defaultValues,
        medications: storedForm ? storedForm.medications : [],
      };

  const handleSubmit = (values) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            toast.success("Concussion Form Updated Successfully");
            history.push(`/concussion-forms`);
          } else {
            history.push(`/concussion-forms/7/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        });
    } else {
      localStorage.setItem("ConcussionForm6", JSON.stringify(values));
      history.push("/concussion-forms/7");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Medical History</span>
        <span className="form-progress">6/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => {
          const addMedication = () => {
            const name = values.nameInput;
            const dose = values.doseInput;

            if (name.trim() && dose.trim()) {
              setFieldValue("medications", [
                ...values.medications,
                { name, dose },
              ]);
              setFieldValue("nameInput", "");
              setFieldValue("doseInput", "");
            }
          };

          const removeMedication = (index) => {
            const updatedMedications = values.medications.filter(
              (_, i) => i !== index
            );
            setFieldValue("medications", updatedMedications);
          };

          return (
            <Form
              className="form-wrapper"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.nodeName === "INPUT") {
                  e.preventDefault();
                }
              }}
            >
              <div className="form-content">
                <div className="field-wrapper">
                  <label className="field-label">
                    Which medications are you taking?
                  </label>
                  <Field
                    name="nameInput"
                    className="field-input"
                    value={values.nameInput || ""}
                    onChange={(e) => setFieldValue("nameInput", e.target.value)}
                  />
                  <ErrorMessage
                    name="nameInput"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="field-wrapper">
                  <label className="field-label">Dose</label>
                  <Field
                    name="doseInput"
                    className="field-input"
                    placeholder="Type a dose"
                    value={values.doseInput || ""}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="add-btn"
                    onClick={addMedication}
                  >
                    +
                  </button>
                  {values.medications.length > 0 && (
                    <div className="dose-list">
                      {values.medications.map((med, index) => (
                        <div key={index} className="dose-item">
                          <span className="dose-text">
                            {med.name} ({med.dose})
                          </span>
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => removeMedication(index)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  <ErrorMessage
                    name="dose"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="btns-wrapper">
                <button
                  type="button"
                  className="form-btns back-btn"
                  disabled={isSubmitting}
                  onClick={() =>
                    history.push(
                      id ? `/concussion-forms/5/${id}` : "/concussion-forms/5"
                    )
                  }
                >
                  BACK
                </button>
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                >
                  NEXT
                </button>
                {id && (
                  <button
                    type="submit"
                    className="form-btns next-btn"
                    disabled={isSubmitting}
                    onClick={() => setSave(true)}
                  >
                    SAVE
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MedicalHistory2;
