import {
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_CREATE_FAIL,
  PATIENT_CREATE_RESET,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_FAIL,
  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_RESET,
  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS,
  PATIENTVISIT_LIST_FAIL,
  PATIENT_RECENT_VISIT_LIST_REQUEST,
  PATIENT_RECENT_VISIT_LIST_SUCCESS,
  PATIENT_RECENT_VISIT_LIST_FAIL,
  PATIENT_VISIT_DELETE_REQUEST,
  PATIENT_VISIT_DELETE_SUCCESS,
  PATIENT_VISIT_DELETE_FAIL,
  PATIENT_VISIT_DETAIL_REQUEST,
  PATIENT_VISIT_DETAIL_SUCCESS,
  PATIENT_VISIT_DETAIL_FAIL,
  BULK_PATIENT_VISIT_DELETE_REQUEST,
  BULK_PATIENT_VISIT_DELETE_SUCCESS,
  BULK_PATIENT_VISIT_DELETE_FAIL,
  CONCUSSION_FORMS_REQUEST,
  CONCUSSION_FORMS_SUCCESS,
  CONCUSSION_FORMS_FAIL,
  CONCUSSION_FORM_CREATE_REQUEST,
  CONCUSSION_FORM_CREATE_SUCCESS,
  CONCUSSION_FORM_CREATE_FAIL,
  PATIENT_DETAIL_REQUEST,
  PATIENT_DETAIL_SUCCESS,
  PATIENT_DETAIL_FAIL,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  SWAY_FORM_CREATE_REQUEST,
  SWAY_FORM_CREATE_SUCCESS,
  SWAY_FORM_CREATE_FAIL,
  CONCUSSION_FORM_UPDATE_REQUEST,
  CONCUSSION_FORM_UPDATE_SUCCESS,
  CONCUSSION_FORM_UPDATE_FAIL,
  CONCUSSION_FORM_DELETE_REQUEST,
  CONCUSSION_FORM_DELETE_SUCCESS,
  CONCUSSION_FORM_DELETE_FAIL,
  CONCUSSION_FORM_REQUEST,
  CONCUSSION_FORM_SUCCESS,
  CONCUSSION_FORM_FAIL,
  SWAY_FORM_REQUEST,
  SWAY_FORM_SUCCESS,
  SWAY_FORM_FAIL,
  SWAY_FORMS_REQUEST,
  SWAY_FORMS_SUCCESS,
  SWAY_FORMS_FAIL,
} from "../constants/patientConstants";

export const patientCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_CREATE_REQUEST:
      return { loading: true };

    case PATIENT_CREATE_SUCCESS:
      return { loading: false, success: true };

    case PATIENT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const patientUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_UPDATE_REQUEST:
      return { loading: true };

    case PATIENT_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case PATIENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientListReducer = (state = { patients: [] }, action) => {
  switch (action.type) {
    case PATIENT_LIST_REQUEST:
      return { loading: true, patients: [] };

    case PATIENT_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
      };

    case PATIENT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};
export const patientDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_DETAIL_REQUEST:
      return { loading: true };

    case PATIENT_DETAIL_SUCCESS:
      return { loading: false, success: true, patient: action.payload };

    case PATIENT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENTVISIT_CREATE_REQUEST:
      return { loading: true };

    case PATIENTVISIT_CREATE_SUCCESS:
      return { loading: false, success: true, data: action.payload };

    case PATIENTVISIT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case PATIENTVISIT_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const patientVisitListReducer = (state = { patients: [] }, action) => {
  switch (action.type) {
    case PATIENTVISIT_LIST_REQUEST:
      return { loading: true, patients: [] };

    case PATIENTVISIT_LIST_SUCCESS:
      return {
        loading: false,
        patients: action.payload.data,
      };

    case PATIENTVISIT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const patientsRecentVisitsListReducer = (
  state = { visits: [] },
  action
) => {
  switch (action.type) {
    case PATIENT_RECENT_VISIT_LIST_REQUEST:
      return { loading: true, visits: [] };

    case PATIENT_RECENT_VISIT_LIST_SUCCESS:
      return {
        loading: false,
        visits: action.payload,
      };

    case PATIENT_RECENT_VISIT_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_VISIT_DELETE_REQUEST:
      return { loading: true };

    case PATIENT_VISIT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case PATIENT_VISIT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BULK_PATIENT_VISIT_DELETE_REQUEST:
      return { loading: true };

    case BULK_PATIENT_VISIT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case BULK_PATIENT_VISIT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_VISIT_DETAIL_REQUEST:
      return { loading: true };

    case PATIENT_VISIT_DETAIL_SUCCESS:
      return { loading: false, success: true, visitDetails: action.payload };

    case PATIENT_VISIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const concussionFormsReducer = (state = {}, action) => {
  switch (action.type) {
    case CONCUSSION_FORMS_REQUEST:
      return { loading: true };

    case CONCUSSION_FORMS_SUCCESS:
      return {
        loading: false,
        success: true,
        concussionForms: action.payload.data,
      };

    case CONCUSSION_FORMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const concussionFormReducer = (state = {}, action) => {
  switch (action.type) {
    case CONCUSSION_FORM_REQUEST:
      return { loading: true };

    case CONCUSSION_FORM_SUCCESS:
      return {
        loading: false,
        success: true,
        concussionForm: action.payload.data,
      };

    case CONCUSSION_FORM_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const concussionFormCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONCUSSION_FORM_CREATE_REQUEST:
      return { loading: true };

    case CONCUSSION_FORM_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        concussionForm: action.payload.data,
      };

    case CONCUSSION_FORM_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const concussionFormUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CONCUSSION_FORM_UPDATE_REQUEST:
      return { loading: true };

    case CONCUSSION_FORM_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        concussionForm: action.payload.data,
      };

    case CONCUSSION_FORM_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const concussionFormDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CONCUSSION_FORM_DELETE_REQUEST:
      return { loading: true };

    case CONCUSSION_FORM_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CONCUSSION_FORM_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const swayFormCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SWAY_FORM_CREATE_REQUEST:
      return { loading: true };

    case SWAY_FORM_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        concussionForm: action.payload.data,
      };

    case SWAY_FORM_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const swayFormsReducer = (state = {}, action) => {
  switch (action.type) {
    case SWAY_FORMS_REQUEST:
      return { loading: true };

    case SWAY_FORMS_SUCCESS:
      return {
        loading: false,
        success: true,
        swayForms: action.payload.data,
      };

    case SWAY_FORMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const swayFormReducer = (state = {}, action) => {
  switch (action.type) {
    case SWAY_FORM_REQUEST:
      return { loading: true };

    case SWAY_FORM_SUCCESS:
      return {
        loading: false,
        success: true,
        swayForm: action.payload.data,
      };

    case SWAY_FORM_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
