import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const validationSchema = Yup.object().shape({});

const MedicalHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    hospitalized_for_head_injury: "N",
    hopitalized_explanantion: "",
    treated_for_headache_disorder: "N",
    diagnosed_with_learning_disability: "N",
    diagnosed_with_adhd: "N",
    diagnosed_with_psychological_disorder: "N",
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm5"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            history.push(`/concussion-forms`);
            toast.success("Concussion Form Updated Successfully");
          } else {
            history.push(`/concussion-forms/6/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        })
        .finally(() => setSubmitting(false));
    } else {
      localStorage.setItem("ConcussionForm5", JSON.stringify(values));
      history.push("/concussion-forms/6");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Medical History</span>
        <span className="form-progress">5/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Have you been hospitalized for a head injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field
                      name="hospitalized_for_head_injury"
                      key={option.value}
                    >
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "hospitalized_for_head_injury",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="hospitalized_for_head_injury"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Explain</label>
                <Field
                  name="hopitalized_explanantion"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="hopitalized_explanantion"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Diagnosed/Treated for headache disorders?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field
                      name="treated_for_headache_disorder"
                      key={option.value}
                    >
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "treated_for_headache_disorder",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="treated_for_headache_disorder"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Diagnosed with a learning disability?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field
                      name="diagnosed_with_learning_disability"
                      key={option.value}
                    >
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "diagnosed_with_learning_disability",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="diagnosed_with_learning_disability"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Diagnosed with ADHD?</label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="diagnosed_with_adhd" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "diagnosed_with_adhd",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="diagnosed_with_adhd"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Diagnosed with depression, anxiety or other psychological
                  disorder?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field
                      name="diagnosed_with_psychological_disorder"
                      key={option.value}
                    >
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "diagnosed_with_psychological_disorder",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="diagnosed_with_psychological_disorder"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/4/${id}` : "/concussion-forms/4"
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MedicalHistory;
