import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getSwayForm } from "../../actions/patientActions";
import Loader from "../loader/Loader";
import { formatDateTime } from "../../helper";

const VISIT_TYPES = {
  followup: "Follow-Up Visit",
  first_visit: "First Visit",
};

export default function SwayFormDetail() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const { loading, swayForm } = useSelector((state) => state.swayForm);

  useEffect(() => {
    dispatch(getSwayForm(token, params.id)).catch((error) => {
      toast.error("Error fetching sway form");
      history.push("/sway-forms");
    });
  }, [dispatch, token, params.id]);

  const handleBack = () => {
    window.history.back();
  };

  const renderInfoRow = (label, value, formatter = (val) => val) => (
    <div className="form-detail-row">
      <span className="form-detail-label">{label}</span>
      <span className="form-detail-value">
        {value ? formatter(value) : "N/A"}
      </span>
    </div>
  );

  if (loading) return <Loader />;
  if (!swayForm) return <div>No form data available</div>;

  return (
    <div className="concussion-form-detail">
      <div className="d-header">
        <ArrowBackIcon
          fontSize="large"
          onClick={handleBack}
          className="back-detail"
        />
        <div>
          <h3>Sway Scores Form</h3>
          <p className="text-center mb-5">
            {formatDateTime(swayForm.created_at)}
          </p>
        </div>
      </div>
      <div className="form-details-container">
        <div className="details-section">
          <h4>Patient Information</h4>
          {renderInfoRow("ID Number", swayForm.id_number)}
          {renderInfoRow("Date of Test", swayForm.date_of_test)}
          {renderInfoRow("Subject Group", swayForm.subject_group)}
          {renderInfoRow(
            "Visit type",
            swayForm.visit_type,
            (val) => VISIT_TYPES[val] || "N/A"
          )}
          {renderInfoRow("Notes", swayForm.notes)}
        </div>

        <div className="details-section">
          <h4>Balance</h4>
          {renderInfoRow("mBESS", swayForm.mbess)}
          <h4>Cognitive</h4>
          {renderInfoRow("Memory", swayForm.memory)}
          {renderInfoRow("Reaction Time", swayForm.reaction_time)}
          {renderInfoRow("Impulse Control", swayForm.impulsive_control)}
          {renderInfoRow("Inspection Time", swayForm.inspection_time)}
        </div>
      </div>
    </div>
  );
}
