import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { logoutUser } from "../../redux/logins";
import "./header.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import signout from "../../assets/img/svg/Exit.svg";
import formsIcon from "../../assets/img/svg/file.svg";
import formIcon from "../../assets/img/svg/forms.svg";
import dashboardIcon from "../../assets/img/svg/dashboard.svg";
import studiesIcon from "../../assets/img/svg/studies-icon.svg";
import trashIcon from "../../assets/img/svg/trashcan.svg";
import Notifications from "./Notifications";

const personalDropdownData = [
  {
    title: "Patient trash",
    icon: trashIcon,
    to: "/deleted-patient-list",
  },
  {
    title: "Visit trash",
    icon: trashIcon,
    to: "/deleted-visit-list",
  },
];

const researchDropdownData = [
  {
    title: "HRI Study",
    icon: studiesIcon,
    subMenu: [
      {
        title: "Dashboard",
        icon: dashboardIcon,
        to: "/dashboard-research",
      },
      {
        title: "Concussion Forms",
        icon: formIcon,
        subMenu: [
          {
            title: "Concussion Intake",
            icon: formsIcon,
            to: "/concussion-forms",
          },
          {
            title: "SWAY",
            icon: formsIcon,
            to: "/sway-forms",
          },
        ],
      },
    ],
  },
];

const Header = () => {
  const token = useSelector((state) => state.loginData.token);
  const [activeNav, setActiveNav] = useState("");
  const location = useLocation();
  const activeTab = location.pathname.split("/").filter(Boolean).pop();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userData, setUserData] = useState();
  const [isFirmwareAdmin, setIsFirmwareAdmin] = useState(false);
  const [isResearchDoctor, setIsResearchDoctor] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicDropdownRef = useRef(null);
  const researchDropdownRef = useRef(null);
  const userDropdownRef = useRef(null);

  // Research sub menu of forms
  const [openSubMenus, setOpenSubMenus] = useState({});

  const toggleSubMenu = (index, level = 0) => {
    setOpenSubMenus((prevState) => {
      const newState = { ...prevState };
      newState[level] = newState[level] === index ? null : index;
      return newState;
    });
  };

  useEffect(() => {
    if (token) {
      let data = token.split(".")[1];
      const parsedData = JSON.parse(atob(data));
      setUserData(parsedData.data[0]);
      setIsFirmwareAdmin(!!parsedData.data[0].is_firmware_admin);
      setIsResearchDoctor(!!parsedData.data[0].is_research_doctor);
    } else {
      setUserData({ doctor_id: 0, username: null, email: null });
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [token]);

  const handleClickOutside = (event) => {
    if (
      clinicDropdownRef.current &&
      !clinicDropdownRef.current.contains(event.target)
    ) {
      setActiveNav("");
    }

    if (
      researchDropdownRef.current &&
      !researchDropdownRef.current.contains(event.target)
    ) {
      setActiveNav("");
      setOpenSubMenus({});
    }

    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setShowUserMenu(false);
    }
  };

  const handleActiveNav = (nav) => {
    if (nav === activeNav) {
      setActiveNav("");
    } else {
      setActiveNav(nav);
    }
  };

  const toggleShowUserMenu = () => setShowUserMenu((prevState) => !prevState);

  const handleSignout = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/logout`).then((res) => {
      if (res.data.status === true) {
        dispatch(logoutUser());
        localStorage.clear();
        history.push({
          pathname: "/login",
        });
      }
    });
  };

  const getUserInitials = (username) => {
    if (!username) return "?";
    const names = username.trim().split(" ");
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (
      names[0].charAt(0) + names[names.length - 1].charAt(0)
    ).toUpperCase();
  };

  const closeMenus = () => {
    setActiveNav("");
    setShowUserMenu(false);
    setOpenSubMenus({});
  };

  const DropDownItemWrapper = ({
    item,
    index,
    level,
    openSubMenus,
    toggleSubMenu,
    closeMenus,
  }) => {
    const isSubmenuOpen = openSubMenus[level] === index;

    return (
      <div className="dropdown-item-wrapper">
        <DropDownItem
          item={item}
          handleClose={closeMenus}
          isSubmenuOpen={isSubmenuOpen}
          toggleSubmenu={() => toggleSubMenu(index, level)}
        />

        {item.subMenu && isSubmenuOpen && (
          <div className="sub-menu">
            {item.subMenu.map((subItem, subIndex) => (
              <DropDownItemWrapper
                key={subIndex}
                item={subItem}
                index={subIndex}
                level={level + 1}
                openSubMenus={openSubMenus}
                toggleSubMenu={toggleSubMenu}
                closeMenus={closeMenus}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <header className="header">
      <Link to={"/dashboard"}>
        <div className="logo-container">
          <img
            src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
            alt="censyn"
          />
        </div>
      </Link>
      <nav>
        <ul className="navigation">
          <li
            onClick={() => handleActiveNav("clinic")}
            className={`${activeTab === "dashboard" ? "active-nav" : ""}`}
          >
            Clinic
          </li>
          {activeNav === "clinic" && (
            <div className="clinic-dropdown" ref={clinicDropdownRef}>
              {personalDropdownData.map((item, index) => (
                <DropDownItem
                  key={index}
                  item={item}
                  handleClose={closeMenus}
                />
              ))}
            </div>
          )}
          {isResearchDoctor && (
            <>
              {activeNav === "research" && (
                <div className="research-dropdown" ref={researchDropdownRef}>
                  {researchDropdownData.map((item, index) => (
                    <DropDownItemWrapper
                      key={index}
                      item={item}
                      index={index}
                      level={0} // Root level
                      openSubMenus={openSubMenus}
                      toggleSubMenu={toggleSubMenu}
                      closeMenus={closeMenus}
                    />
                  ))}
                </div>
              )}
              <li
                onClick={() => handleActiveNav("research")}
                className={`${
                  activeTab === "dashboard-research" ? "active-nav" : ""
                }`}
              >
                Research
              </li>
            </>
          )}

          {isFirmwareAdmin && (
            <li
              onClick={() => {
                handleActiveNav("firmware");
                history.push("/firmware");
              }}
              className={`${activeTab === "firmware" ? "active-nav" : ""}`}
            >
              Firmware
            </li>
          )}
        </ul>
      </nav>

      <div className="header-user-icon">
        <div className="mr-3 mt-1">
          <Notifications />
        </div>

        <div className="user-initials-circle" onClick={toggleShowUserMenu}>
          {userData && userData.username
            ? getUserInitials(userData.username)
            : "?"}
        </div>
        {showUserMenu && (
          <div className="user-dropdown" ref={userDropdownRef}>
            <div className="user-dropdown-header">
              <div className="user-initials-circle">
                {userData && userData.username
                  ? getUserInitials(userData.username)
                  : "?"}
              </div>
              <div>
                <p className="header-username">
                  {userData && userData.username ? userData.username : "N/A"}
                </p>
                <p className="header-useremail">
                  {userData && userData.email ? userData.email : "N/A"}
                </p>
              </div>
            </div>
            {/* <DropDownItem
              item={{ title: "Manage Account", icon: manageIcon, to: "#" }}
              handleClose={closeMenus}
            /> */}
            <div className="divider"></div>
            {/* <DropDownItem
              item={{
                title: "Settings",
                icon: settingIcon,
                to: "/account-settings",
              }}
              handleClose={closeMenus}
            /> */}
            <DropDownItem
              item={{ title: "Sign out", icon: signout, to: "#" }}
              handleClick={handleSignout}
              handleClose={closeMenus}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

export const DropDownItem = ({
  item,
  handleClose = () => {},
  handleClick = () => {},
  isSubmenuOpen = false,
  toggleSubmenu = () => {},
}) => {
  const { title, icon, to, subMenu } = item;
  const handleItemClick = () => {
    if (subMenu) {
      toggleSubmenu();
    } else {
      handleClick();
      handleClose();
    }
  };
  return (
    <>
      {to ? (
        <Link to={to}>
          <div className="dropdown-item" onClick={handleItemClick}>
            <p>{title}</p>
            <img src={icon} alt="personal" />
          </div>
        </Link>
      ) : (
        <div
          className={`dropdown-item ${title === "Delete" ? "delete-clr" : ""}`}
          onClick={handleItemClick}
        >
          <p>{title}</p>
          <img
            src={icon}
            alt="personal"
            className={` ${title === "Delete" ? "delete-clr" : ""}`}
          />
        </div>
      )}
    </>
  );
};
