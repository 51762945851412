import React, { useState, useRef, useEffect } from "react";
import notificationsIcon from "../../assets/img/svg/notifications.svg";
import { useHistory } from "react-router-dom";
import "./notifications.css";

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const dropdownRef = useRef(null);

  const [notifications] = useState([
    {
      id: 1,
      message: "A new visit is created.",
      timestamp: new Date(2025, 1, 7, 14, 30),
      seen: false,
    },
    {
      id: 2,
      message: "A visit with ID 12241 is deleted.",
      timestamp: new Date(2025, 1, 7, 12, 15),
      seen: false,
    },
    {
      id: 3,
      message: "A new visit is created.",
      timestamp: new Date(2025, 1, 6, 18, 45),
      seen: true,
    },
  ]);

  const hasUnseenNotifications = notifications.some((notif) => !notif.seen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleNavigate = () => {
    history.push("/recording/940");
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const diff = now - timestamp;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return "Just now";
  };

  return (
    <div className="notifications-container" ref={dropdownRef}>
      <div
        className="notifications-icon-button border-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={notificationsIcon}
          alt="notifications"
          className="notifications-icon"
        />
        {hasUnseenNotifications && <span className="notifications-badge" />}
      </div>

      {isOpen && (
        <div className="notifications-dropdown">
          <div className="notifications-header">
            <h3>Notifications</h3>
          </div>

          <div className="notifications-content">
            {notifications.length === 0 ? (
              <p className="notifications-empty">No notifications</p>
            ) : (
              <div
                className="notifications-list"
                onClick={() => handleNavigate()}
              >
                {notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`notification-item ${
                      !notification.seen ? "unseen" : ""
                    }`}
                  >
                    <p className="notification-message">
                      {notification.message}
                    </p>
                    <p className="notification-time">
                      {formatTimestamp(notification.timestamp)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {notifications.length > 0 && (
            <div className="notifications-footer">
              <button className="mark-read-button">Mark all as read</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
