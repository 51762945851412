import React, { Fragment } from "react";
import Header from "../header/Header";
import PatientVisits from "../patient-visits/PatientVisits";
import Patients from "../patients/Patients";
export default function PatientDashboardResearch() {
  return (
    <Fragment>
      <Header />
      <PatientVisits category={"Research"} />
      <Patients category={"Research"} />
    </Fragment>
  );
}
