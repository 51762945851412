import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../../actions/patientActions";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const injuryOptions = [
  "Lying motionless",
  "Falling unprotected to the ground",
  "Impact seizure",
  "Balance/gait difficulties",
  "Disorientation/confusion",
  "Blank/vacant look",
];

const CurrentInjury2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    witnessed: [],
    lost_consciousness: "N",
    trouble_remembering_before: "N",
    trouble_remembering_after: "N",
    neck_pain: "N",
  };
  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm8"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            toast.success("Concussion Form Updated Successfully");
            history.push(`/concussion-forms`);
          } else {
            history.push(`/concussion-forms/9/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        })
        .finally(() => setSubmitting(false));
    } else {
      localStorage.setItem("ConcussionForm8", JSON.stringify(values));
      history.push("/concussion-forms/9");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">8/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Immediately following the injury, did anyone witness:
                </label>
                <div role="group" aria-labelledby="checkbox-group">
                  {injuryOptions.map((option, index) => (
                    <label key={index} className="option-label">
                      <Field
                        type="checkbox"
                        name="witnessed"
                        value={option}
                        className="checkbox-input"
                      />
                      {option}
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  name="witnessed"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do you lose consciousness?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="lost_consciousness" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "lost_consciousness",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="lost_consciousness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do you have trouble remembering the events BEFORE the injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="trouble_remembering_before" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "trouble_remembering_before",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="trouble_remembering_before"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Do you have trouble remembering the events AFTER the injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="trouble_remembering_after" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "trouble_remembering_after",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="trouble_remembering_after"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do you have neck pain since the injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="neck_pain" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("neck_pain", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="neck_pain"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/7/${id}` : "/concussion-forms/7"
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury2;
