import axios from "axios";
import { toast } from "react-toastify";
import {
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_LIST_FAIL,
  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS,
  PATIENT_CREATE_FAIL,
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS,
  PATIENT_RECENT_VISIT_LIST_REQUEST,
  PATIENT_RECENT_VISIT_LIST_SUCCESS,
  PATIENT_RECENT_VISIT_LIST_FAIL,
  PATIENT_VISIT_DELETE_REQUEST,
  PATIENT_VISIT_DELETE_SUCCESS,
  PATIENT_VISIT_DELETE_FAIL,
  PATIENT_VISIT_DETAIL_REQUEST,
  PATIENT_VISIT_DETAIL_SUCCESS,
  PATIENT_VISIT_DETAIL_FAIL,
  CSV_FILE_REQUEST,
  CSV_FILE_SUCCESS,
  CSV_FILE_FAIL,
  BULK_PATIENT_VISIT_DELETE_REQUEST,
  BULK_PATIENT_VISIT_DELETE_SUCCESS,
  BULK_PATIENT_VISIT_DELETE_FAIL,
  CONCUSSION_FORMS_REQUEST,
  CONCUSSION_FORMS_SUCCESS,
  CONCUSSION_FORMS_FAIL,
  CONCUSSION_FORM_REQUEST,
  CONCUSSION_FORM_SUCCESS,
  CONCUSSION_FORM_FAIL,
  CONCUSSION_FORM_CREATE_REQUEST,
  CONCUSSION_FORM_CREATE_SUCCESS,
  CONCUSSION_FORM_CREATE_FAIL,
  CONCUSSION_FORM_UPDATE_REQUEST,
  CONCUSSION_FORM_UPDATE_SUCCESS,
  CONCUSSION_FORM_UPDATE_FAIL,
  CONCUSSION_FORM_DELETE_REQUEST,
  CONCUSSION_FORM_DELETE_SUCCESS,
  CONCUSSION_FORM_DELETE_FAIL,
  PATIENT_DETAIL_REQUEST,
  PATIENT_DETAIL_SUCCESS,
  PATIENT_DETAIL_FAIL,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  SWAY_FORM_CREATE_REQUEST,
  SWAY_FORM_CREATE_SUCCESS,
  SWAY_FORM_CREATE_FAIL,
  SWAY_FORM_UPDATE_REQUEST,
  SWAY_FORM_UPDATE_SUCCESS,
  SWAY_FORM_UPDATE_FAIL,
  SWAY_FORM_DELETE_REQUEST,
  SWAY_FORM_DELETE_SUCCESS,
  SWAY_FORM_DELETE_FAIL,
  SWAY_FORM_REQUEST,
  SWAY_FORM_SUCCESS,
  SWAY_FORM_FAIL,
  SWAY_FORMS_REQUEST,
  SWAY_FORMS_SUCCESS,
  SWAY_FORMS_FAIL,
} from "../constants/patientConstants";

export const createPatient = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_CREATE_REQUEST,
    });

    const {} = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${patient.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/patient/create/`,
      patient,
      config
    );
    dispatch({
      type: PATIENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePatient = (token, id, patientData) => async (dispatch) => {
  try {
    dispatch({
      type: PATIENT_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/patient/${id}`,
      patientData,
      config
    );
    dispatch({
      type: PATIENT_UPDATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PATIENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw new Error(errorMessage);
  }
};

export const listPatient = (token, params) => async (dispatch) => {
  try {
    const queryParams = new URLSearchParams(params).toString();
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/list/?${queryParams}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPatientDetails = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_DETAIL_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/patient-details/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    dispatch({
      type: PATIENT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletedPatientList = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/deleted-patients`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletedVisitList = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/deleted-patient-visits`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPatientVisit = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENTVISIT_CREATE_REQUEST,
    });

    const {} = getState();
    // get all the keys of patient and append them in form data
    const formData = new FormData();
    Object.keys(patient).forEach((key) => {
      formData.append(key, patient[key]);
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${patient.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/patient/createvisit/${patient.patient_id}/`,
      formData,
      config
    );

    if (data.code === 200) {
      toast.success("Patient Visit Created Successfully", {
        position: "top-right",
      });
    } else {
      toast.error("Something bad happened", {
        position: "top-right",
      });
    }

    dispatch({
      type: PATIENTVISIT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Patient Visit Creation Failed", {
      position: "top-right",
    });
    dispatch({
      type: PATIENTVISIT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPatientVisit = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENTVISIT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/doc/docs/`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENTVISIT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENTVISIT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPatientRecentVisits = (token, params) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_RECENT_VISIT_LIST_REQUEST });

    const queryParams = new URLSearchParams(params).toString();

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/recent-visits/list/?${queryParams}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_RECENT_VISIT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_RECENT_VISIT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePatientVisit = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_VISIT_DELETE_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/patient/delete-patient-visit/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_VISIT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_VISIT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPatientVisitDetails = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_VISIT_DETAIL_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/related-visit-record/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_VISIT_DETAIL_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: PATIENT_VISIT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getCsvFile = (token, id, eeg_file_s3) => async (dispatch) => {
  try {
    dispatch({ type: CSV_FILE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/get-presigned-urls`,
      {
        data: [
          {
            id: id,
            eeg_file_s3: eeg_file_s3,
          },
        ],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (data.code === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      dispatch({
        type: CSV_FILE_SUCCESS,
        payload: data?.data[0],
      });
    }

    return data?.data[0];
  } catch (error) {
    dispatch({
      type: CSV_FILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const bulkDeletePatientVisit = (token, detailId) => async (dispatch) => {
  try {
    dispatch({ type: BULK_PATIENT_VISIT_DELETE_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/patient/bulk-delete-patient-visit`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          ids: [detailId],
        },
      }
    );
    dispatch({
      type: BULK_PATIENT_VISIT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BULK_PATIENT_VISIT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getConcussionForms = (token) => async (dispatch) => {
  try {
    dispatch({ type: CONCUSSION_FORMS_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/concussion-forms`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: CONCUSSION_FORMS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CONCUSSION_FORMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getConcussionForm = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: CONCUSSION_FORM_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/concussion-forms/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: CONCUSSION_FORM_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CONCUSSION_FORM_FAIL,
      payload: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const createConcussionForm = (token, formData) => async (dispatch) => {
  try {
    dispatch({
      type: CONCUSSION_FORM_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/concussion-forms`,
      formData,
      config
    );
    dispatch({
      type: CONCUSSION_FORM_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CONCUSSION_FORM_CREATE_FAIL,
      payload: errorMessage,
    });

    throw new Error(errorMessage);
  }
};

export const updateConcussionForm =
  (token, id, formData) => async (dispatch) => {
    try {
      dispatch({
        type: CONCUSSION_FORM_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `${token}`,
        },
      };

      const { data } = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/concussion-forms/${id}`,
        formData,
        config
      );
      dispatch({
        type: CONCUSSION_FORM_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: CONCUSSION_FORM_UPDATE_FAIL,
        payload: errorMessage,
      });

      throw new Error(errorMessage);
    }
  };

export const deleteConcussionForm = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: CONCUSSION_FORM_DELETE_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/concussion-forms/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: CONCUSSION_FORM_DELETE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CONCUSSION_FORM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const createSwayForm = (token, formData) => async (dispatch) => {
  try {
    dispatch({
      type: SWAY_FORM_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/sway-forms`,
      formData,
      config
    );
    dispatch({
      type: SWAY_FORM_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SWAY_FORM_CREATE_FAIL,
      payload: errorMessage,
    });

    throw new Error(errorMessage);
  }
};

export const updateSwayForm = (token, id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: SWAY_FORM_CREATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/sway-forms/${id}`,
      formData,
      config
    );
    dispatch({
      type: SWAY_FORM_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SWAY_FORM_CREATE_FAIL,
      payload: errorMessage,
    });

    throw new Error(errorMessage);
  }
};

export const getSwayForms = (token) => async (dispatch) => {
  try {
    dispatch({ type: SWAY_FORMS_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sway-forms`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: SWAY_FORMS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: SWAY_FORMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getSwayForm = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: SWAY_FORM_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/sway-forms/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: SWAY_FORM_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const errorMessage =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SWAY_FORM_FAIL,
      payload: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const deleteSwayForm = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: SWAY_FORM_DELETE_REQUEST });

    const { data } = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/sway-forms/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: SWAY_FORM_DELETE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: SWAY_FORM_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};
