import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const validationSchema = Yup.object().shape({
  drinks_per_week: Yup.number()
    .nullable()
    .typeError("Drinks per week must be a number"),
  how_much_tobacco_per_day: Yup.number()
    .nullable()
    .typeError("Tobacco per day must be a number"),
});

const SocialHistory2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [save, setSave] = useState(false);
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    eating_habits_changed: "N",
    eating_habits_explanation: "",
    drink_alcohol: "N",
    drinks_per_week: "",
    tobacco_products: "N",
    how_much_tobacco_per_day: null,
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm4"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            toast.success("Concussion Form Updated Successfully");
            history.push(`/concussion-forms`);
          } else {
            history.push(`/concussion-forms/5/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        })
        .finally(() => setSubmitting(false));
    } else {
      localStorage.setItem("ConcussionForm4", JSON.stringify(values));
      history.push("/concussion-forms/5");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Social History</span>
        <span className="form-progress">4/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Have your eating habits changed since your injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="eating_habits_changed" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "eating_habits_changed",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="eating_habits_changed"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Please explain</label>
                <Field
                  name="eating_habits_explanation"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="eating_habits_explanation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Do you drink alcohol?</label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="drink_alcohol" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("drink_alcohol", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="drink_alcohol"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">How many drinks per week?</label>
                <Field
                  name="drinks_per_week"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="drinks_per_week"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do you use tobacco products?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="tobacco_products" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("tobacco_products", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="tobacco_products"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">How much per day?</label>
                <Field
                  name="how_much_tobacco_per_day"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="how_much_tobacco_per_day"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/3/${id}` : `/concussion-forms/3`
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SocialHistory2;
