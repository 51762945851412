import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  listPatient,
  getSwayForm,
  updateSwayForm,
} from "../../actions/patientActions";

const validationSchema = Yup.object().shape({
  id_number: Yup.string().required("Please select Id Number"),
});

const SwayForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [save, setSave] = useState(false);
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state.patientList);
  const token = useSelector((state) => state.loginData.token);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const swayFormData = useSelector((state) => state.swayForm);
  const { swayForm = [] } = swayFormData;

  const defaultValues = {
    patient: "",
    id_number: "",
    date_of_test: null,
    visit_type: "",
    subject_group: "",
    notes: "",
  };
  const storedForm = JSON.parse(localStorage.getItem("swayFormData"));
  const initialValues = id
    ? { ...defaultValues, ...swayForm }
    : { ...defaultValues, ...storedForm };

  useEffect(() => {
    if (id) {
      dispatch(getSwayForm(token, id)).catch((error) => {
        toast.error("Error! " + error.message);
        history.push("/sway-forms");
      });
    }
  }, [dispatch, token, id]);

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch, token]);

  const { patients = [] } = patientList;

  useEffect(() => {
    if (patients && patients.length) {
      const filtered = patients
        .map((patient) => ({
          ...patient,
          isMatch: String(patient.id_number)
            .toLowerCase()
            .includes(String(searchTerm).toLowerCase()),
        }))
        .sort((a, b) => b.isMatch - a.isMatch);
      setFilteredPatients(filtered);
    }
  }, [searchTerm, patients]);

  const handleSubmit = (values, { setSubmitting }) => {
    const validId = patients.some(
      (patient) => patient?.id_number === values.id_number
    );

    if (validId) {
      if (id) {
        dispatch(updateSwayForm(token, id, values))
          .then(() => {
            if (save) {
              history.push(`/sway-forms`);
              toast.success("Sway Form Updated Successfully");
            } else {
              history.push(`/sway-forms/2/${id}`);
            }
          })
          .catch((error) => {
            toast.error("Error! " + error.message);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        localStorage.setItem("swayFormData", JSON.stringify(values));
        history.push(`/sway-forms/2`);
      }
    } else {
      toast.error("Please select a valid ID Number");
      setSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">SWAY Scores Form</span>
        <span className="form-progress">1/2</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
        }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper" style={{ position: "relative" }}>
                <label className="field-label">ID Number</label>
                <Field
                  name="id_number"
                  placeholder="Search by ID Number"
                  className="field-input"
                  value={values.id_number ?? searchTerm}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSearchTerm(value);
                    setFieldValue("id_number", value);
                    setShowDropdown(true);
                  }}
                  onFocus={() => setShowDropdown(true)}
                  onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                />
                {showDropdown && filteredPatients.length > 0 && (
                  <ul className="dropdown-list">
                    {filteredPatients.map((patient) => (
                      <li
                        key={patient.id_number}
                        className="dropdown-item"
                        onClick={() => {
                          setSearchTerm(patient.id_number);
                          setFieldValue("id_number", patient.id_number);
                          setFieldValue("patient", patient.id);
                          setShowDropdown(false);
                          setFieldTouched("id_number", true);
                        }}
                      >
                        {`${patient.id_number}`}
                      </li>
                    ))}
                  </ul>
                )}
                {!searchTerm && (
                  <ErrorMessage
                    name="id_number"
                    component="div"
                    className="error-message"
                  />
                )}
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Test</label>
                <Field
                  name="date_of_test"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="date_of_test"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Visit Type</label>
                <Field as="select" name="visit_type" className="field-input">
                  <option value="">Select Visit Type</option>
                  <option value="first_visit">First Visit</option>
                  <option value="followup">Follow-Up Visit</option>
                </Field>
                <ErrorMessage
                  name="visit_type"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Subject Group</label>
                <Field as="select" name="subject_group" className="field-input">
                  <option value="" className="options-font">
                    Select Subject Group
                  </option>
                  <option value="Cohort" className="options-font">
                    Cohort
                  </option>
                  <option value="Control" className="options-font">
                    Control
                  </option>
                </Field>
                <ErrorMessage
                  name="subject_group"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/sway-forms")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SwayForm;
