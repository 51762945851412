import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getConcussionForm } from "../../actions/patientActions";
import Loader from "../loader/Loader";
import { formatDateTime } from "../../helper";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const GENDER_MAP = {
  M: "Male",
  F: "Female",
};

const VISIT_TYPES = {
  followup: "Follow-Up Visit",
  first_visit: "First Visit",
};

const HAND_MAP = {
  R: "Right",
  L: "Left",
  A: "Ambidextrous",
};

const PERFORMANCE_MAP = {
  ABV: "Above Average",
  AVG: "Average",
  BLW: "Below Average",
};

export default function ConcussionFormDetail() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const { loading, concussionForm } = useSelector(
    (state) => state.concussionForm
  );

  useEffect(() => {
    dispatch(getConcussionForm(token, params.id)).catch((error) => {
      toast.error("Error fetching concussion form");
      history.push("/concussion-forms");
    });
  }, [dispatch, token, params.id]);

  const handleBack = () => {
    window.history.back();
  };

  const renderInfoRow = (label, value, formatter = (val) => val) => (
    <div className="form-detail-row">
      <span className="form-detail-label">{label}</span>
      <span className="form-detail-value">
        {value ? formatter(value) : "N/A"}
      </span>
    </div>
  );

  const renderSymptomScore = (label, score) => (
    <div className="form-detail-row">
      <span className="form-detail-label">{label}</span>
      <span className="form-detail-value">{score}</span>
    </div>
  );

  const mapYesNo = (value) => {
    const choice = CHOICES.find((choice) => choice.value === value);
    return choice ? choice.label : "N/A";
  };

  if (loading) return <Loader />;
  if (!concussionForm) return <div>No form data available</div>;

  return (
    <div className="concussion-form-detail">
      <div className="d-header">
        <ArrowBackIcon
          fontSize="large"
          onClick={handleBack}
          className="back-detail"
        />
        <div>
          <h3>Concussion Intake Form</h3>
          <p className="text-center mb-5">
            {formatDateTime(concussionForm.created_at)}
          </p>
        </div>
      </div>
      <div className="form-details-container">
        {/* Patient Information Section */}
        <div className="details-section">
          <h4>Patient Information</h4>
          {renderInfoRow("ID Number", concussionForm.id_number)}
          {renderInfoRow("Date of Birth", concussionForm.date_of_birth)}
          {renderInfoRow(
            "Gender",
            concussionForm.gender,
            (val) => GENDER_MAP[val] || "N/A"
          )}
          {renderInfoRow(
            "Dominant Hand",
            concussionForm.dominant_hand,
            (val) => HAND_MAP[val] || "N/A"
          )}
          {renderInfoRow("Date of Test", concussionForm.date_of_test)}
          {renderInfoRow("Subject Group", concussionForm.subject_group)}
          {renderInfoRow(
            "Current Year in School",
            concussionForm.current_year_in_school
          )}
          {renderInfoRow(
            "Years of Education Completed",
            concussionForm.years_education_completed
          )}
        </div>

        {/* Concussion History Section */}
        <div className="details-section">
          <h4>Concussion History</h4>
          {renderInfoRow(
            "Past Concussions",
            concussionForm.past_concussions_count
          )}
          {renderInfoRow(
            "Most Recent Concussion",
            concussionForm.most_recent_concussion_date
          )}
          {renderInfoRow("Recovery Time", concussionForm.recovery_time)}
          {renderInfoRow("Primary Symptoms", concussionForm.primary_symptoms)}
          {renderInfoRow("Notes", concussionForm.notes)}
        </div>

        {/* Social History Section */}
        <div className="details-section">
          <h4>Social History</h4>
          {renderInfoRow("Occupation", concussionForm.occupation)}
          {renderInfoRow(
            "School Performance",
            concussionForm.performance_in_school,
            (val) => PERFORMANCE_MAP[val] || val
          )}
          {renderInfoRow(
            "Homework Hours Nightly",
            concussionForm.homework_hours_nightly
          )}
          {renderInfoRow(
            "Sleep Hours Per Night",
            concussionForm.sleep_hours_per_night
          )}
          {renderInfoRow(
            "Sleep Hours Since Injury",
            concussionForm.hours_per_night_since_injury
          )}
          {renderInfoRow(
            "Screen Time per Day",
            concussionForm.screen_time_hours_per_day
          )}
          {renderInfoRow(
            "Eating Habits Changed",
            concussionForm.eating_habits_changed,
            mapYesNo
          )}
          {renderInfoRow(
            "Eating Habits Explanation",
            concussionForm.eating_habits_explanation
          )}
          {renderInfoRow(
            "Alcohol Consumption",
            concussionForm.drink_alcohol,
            mapYesNo
          )}
          {renderInfoRow("Drinks per Week", concussionForm.drinks_per_week)}
          {renderInfoRow(
            "Tobacco Use",
            concussionForm.tobacco_products,
            mapYesNo
          )}
          {renderInfoRow(
            "Tobacco per Day",
            concussionForm.how_much_tobacco_per_day
          )}
        </div>

        {/* Medical History Section */}
        <div className="details-section">
          <h4>Medical History</h4>
          {renderInfoRow(
            "Hospitalized for Head Injury",
            concussionForm.hospitalized_for_head_injury,
            mapYesNo
          )}
          {renderInfoRow(
            "Hospitalization Explanation",
            concussionForm.hopitalized_explanantion
          )}
          {renderInfoRow(
            "Treated for Headache Disorder",
            concussionForm.treated_for_headache_disorder,
            mapYesNo
          )}
          {renderInfoRow(
            "Learning Disability",
            concussionForm.diagnosed_with_learning_disability,
            mapYesNo
          )}
          {renderInfoRow("ADHD", concussionForm.diagnosed_with_adhd, mapYesNo)}
          {renderInfoRow(
            "Psychological Disorder",
            concussionForm.diagnosed_with_psychological_disorder,
            mapYesNo
          )}
          <div className="form-detail-row">
            <span className="form-detail-label">Medications</span>
            <span className="form-detail-value">
              {concussionForm.medications &&
              concussionForm.medications.length > 0
                ? concussionForm.medications
                    .map((med) => `${med.name} (${med.dose})`)
                    .join(", ")
                : "None"}
            </span>
          </div>
        </div>

        {/* Current Injury Section */}
        <div className="details-section">
          <h4>Current Injury Details</h4>
          {renderInfoRow(
            "Injury Explanation",
            concussionForm.injury_explanation
          )}
          {renderInfoRow(
            "Seen Other Physicians",
            concussionForm.seen_other_physicians,
            mapYesNo
          )}
          {renderInfoRow(
            "Other Physicians List",
            concussionForm.other_physicians_list
          )}
          {renderInfoRow(
            "Imaging Performed",
            concussionForm.had_imaging,
            mapYesNo
          )}
          {renderInfoRow("Imaging List", concussionForm.imaging_list)}
          <div className="form-detail-row">
            <span className="form-detail-label">Witnessed Injury</span>
            <span className="form-detail-value">
              {concussionForm.witnessed && concussionForm.witnessed.length > 0
                ? concussionForm.witnessed.join(", ")
                : "N/A"}
            </span>
          </div>
          {renderInfoRow(
            "Lost Consciousness",
            concussionForm.lost_consciousness,
            mapYesNo
          )}
          {renderInfoRow(
            "Memory Issues Before Injury",
            concussionForm.trouble_remembering_before,
            mapYesNo
          )}
          {renderInfoRow(
            "Memory Issues After Injury",
            concussionForm.trouble_remembering_after,
            mapYesNo
          )}
          {renderInfoRow("Neck Pain", concussionForm.neck_pain, mapYesNo)}
        </div>

        {/* Symptoms Assessment Section */}
        <div className="details-section full-width">
          <h4>Symptoms Assessment</h4>
          <div className="symptoms-grid">
            {renderSymptomScore("Headache", concussionForm.headache)}
            {renderSymptomScore("Head Pressure", concussionForm.head_pressure)}
            {renderSymptomScore("Neck Pain", concussionForm.neck_pain_score)}
            {renderSymptomScore("Nausea", concussionForm.nausea)}
            {renderSymptomScore("Dizziness", concussionForm.dizziness)}
            {renderSymptomScore(
              "Vision Problems",
              concussionForm.vision_problems
            )}
            {renderSymptomScore(
              "Balance Problems",
              concussionForm.balance_problems
            )}
            {renderSymptomScore(
              "Light Sensitivity",
              concussionForm.sensitivity_to_light
            )}
            {renderSymptomScore(
              "Noise Sensitivity",
              concussionForm.sensitivity_to_noise
            )}
            {renderSymptomScore(
              "Feeling Slowed Down",
              concussionForm.feeling_slowed_down
            )}
            {renderSymptomScore(
              "Feeling in Fog",
              concussionForm.feeling_in_fog
            )}
            {renderSymptomScore(
              "Not Feeling Right",
              concussionForm.dont_feel_right
            )}
            {renderSymptomScore(
              "Memory Problems",
              concussionForm.memory_problems
            )}
            {renderSymptomScore(
              "Concentration Problems",
              concussionForm.concentration_problems
            )}
            {renderSymptomScore("Fatigue", concussionForm.fatigue)}
            {renderSymptomScore("Confusion", concussionForm.confusion)}
            {renderSymptomScore("Drowsiness", concussionForm.drowsiness)}
            {renderSymptomScore("Anxiety", concussionForm.anxiety)}
            {renderSymptomScore("Irritability", concussionForm.irritability)}
            {renderSymptomScore("Sadness", concussionForm.sadness)}
            {renderSymptomScore("Nervousness", concussionForm.nervousness)}
            {renderSymptomScore(
              "Trouble Falling Asleep",
              concussionForm.trouble_falling_asleep
            )}
          </div>

          <div className="symptom-summary">
            {renderInfoRow("Total Symptoms", concussionForm.total_symptoms)}
            {renderInfoRow(
              "Symptom Severity Score",
              concussionForm.symptom_severity_score
            )}
            {renderInfoRow(
              "Worse with Physical Activity",
              concussionForm.worse_with_physical_activity,
              mapYesNo
            )}
            {renderInfoRow(
              "Worse with Mental Activity",
              concussionForm.worse_with_mental_activity,
              mapYesNo
            )}
            {renderInfoRow(
              "Percent Feeling Normal",
              `${concussionForm.percent_feeling_normal}%`
            )}
            {renderInfoRow(
              "Symptoms Explanation",
              concussionForm.symptoms_explanation
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
