import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const PERFORMANCE_CHOICES = [
  { value: "ABV", label: "Above Average" },
  { value: "AVG", label: "Average" },
  { value: "BLW", label: "Below Average" },
];

const validationSchema = Yup.object().shape({
  occupation: Yup.string().nullable(),
  homework_hours_nightly: Yup.number()
    .typeError("Homework Hours must be a number")
    .nullable(),
  hours_per_night_since_injury: Yup.number()
    .typeError("Sleep Hours must be a number")
    .nullable(),
  screen_time_hours_per_day: Yup.number()
    .typeError("Screen Time must be a number")
    .nullable(),
});
const SocialHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [save, setSave] = useState(false);
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    occupation: "",
    performance_in_school: "ABV",
    homework_hours_nightly: "",
    sleep_hours_per_night: "",
    hours_per_night_since_injury: "",
    screen_time_hours_per_day: "",
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm3"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            history.push(`/concussion-forms`);
            toast.success("Concussion Form Updated Successfully");
          } else {
            history.push(`/concussion-forms/4/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        })
        .finally(() => setSubmitting(false));
    } else {
      localStorage.setItem("ConcussionForm3", JSON.stringify(values));
      history.push("/concussion-forms/4");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Social History</span>
        <span className="form-progress">3/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">Occupation</label>
                <Field name="occupation" className="field-input" />
                <ErrorMessage
                  name="occupation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Performance in school?</label>
                <div role="group" className="tabs-group">
                  {PERFORMANCE_CHOICES.map((choice) => (
                    <Field name="performance_in_school" key={choice.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === choice.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "performance_in_school",
                              choice.value
                            )
                          }
                        >
                          {choice.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="performance_in_school"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  How many hours of homework nightly?
                </label>
                <Field name="homework_hours_nightly" className="field-input" />
                <ErrorMessage
                  name="homework_hours_nightly"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  How many hours do you usually sleep?
                </label>
                <Field name="sleep_hours_per_night" className="field-input" />
                <ErrorMessage
                  name="sleep_hours_per_night"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Hours of sleep per night since injury?
                </label>
                <Field
                  name="hours_per_night_since_injury"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="hours_per_night_since_injury"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Hour of screen time per day?
                </label>
                <Field
                  name="screen_time_hours_per_day"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="screen_time_hours_per_day"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/2/${id}` : `/concussion-forms/2`
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SocialHistory;
