import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import {
  doctorListReducer,
  patientVisitsDetailReducer,
  hospitalListReducer,
} from "./reducers/doctorReducers";
import {
  eegFileReducer,
  eegFilterReducer,
  eegCompareReducer,
} from "./reducers/graphReducers";
import { pwelchPlotReducer } from "./reducers/graphReducers";
import {
  patientCreateReducer,
  patientListReducer,
  patientVisitCreateReducer,
  patientVisitListReducer,
  patientsRecentVisitsListReducer,
  patientVisitDeleteReducer,
  patientVisitDetailsReducer,
  patientVisitsDeleteReducer,
  concussionFormsReducer,
  concussionFormCreateReducer,
  patientDetailReducer,
  patientUpdateReducer,
  concussionFormReducer,
  swayFormsReducer,
  swayFormReducer,
} from "./reducers/patientReducers";
import LoginReducer from "./redux/logins";

const reducer = combineReducers({
  loginData: LoginReducer,
  updatePatient: patientUpdateReducer,
  doctorList: doctorListReducer,
  patientVisitsDetail: patientVisitsDetailReducer,
  patientCreate: patientCreateReducer,
  patientList: patientListReducer,
  patientDetail: patientDetailReducer,
  patientVisitCreate: patientVisitCreateReducer,
  patientVisitList: patientVisitListReducer,
  patientsRecentVisitsList: patientsRecentVisitsListReducer,
  patientVisitDelete: patientVisitDeleteReducer,
  patientVisitsDelete: patientVisitsDeleteReducer,
  patientVisitDetails: patientVisitDetailsReducer,
  eegGraph: eegFileReducer,
  eegCompareGraphs: eegCompareReducer,
  eegGraphFilter: eegFilterReducer,
  pwelchPlot: pwelchPlotReducer,
  concussionForms: concussionFormsReducer,
  concussionForm: concussionFormReducer,
  concussionFormCreate: concussionFormCreateReducer,
  hospitalList: hospitalListReducer,
  swayForms: swayFormsReducer,
  swayForm: swayFormReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
