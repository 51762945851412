import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/logins";
import { Link, useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Footer from "../components/Footer";
import "../assets/css/login.css";
import { ReactComponent as ShowPasswordIcon } from "../assets/img/svg/show-password.svg";
import { ReactComponent as HidePasswordIcon } from "../assets/img/svg/hide-password.svg";
import { ReactComponent as LeftArrow } from "../assets/img/svg/left-triangle.svg";
import { ReactComponent as IconTi } from "../assets/img/svg/circle-tick.svg";
import { ReactComponent as IconTin } from "../assets/img/svg/not-circle.svg";

export default function Login() {
  const dispatch = useDispatch();
  const [loginAttempt, setLoginAttempt] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [passwordType, setPasswordType] = useState("password");
  const togglePasswordType = () =>
    setPasswordType((prevState) =>
      prevState === "password" ? "text" : "password"
    );

  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const login_credentials = {
        username: e.target[0].value,
        password: e.target[1].value,
      };
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/login/`, login_credentials)
        .then((res) => {
          if (res.data.status === true) {
            setLoginAttempt(true);
            setLoading(false);
            dispatch(
              loginUser({ userId: res.data.doctor_id, token: res.data.token })
            );
            localStorage.setItem("status", true);
            localStorage.setItem("userId", res.data.doctor.id);
            localStorage.setItem("token", res.data.token);
            history.push("/dashboard");
          } else {
            setLoginAttempt(false);
            setLoading(false);
          }
        });
    }
  };

  return (
    <>
      <section className="login-main">
        <div className="container h-100">
          <div className="row justify-content-center p-0 m-0 h-100">
            <div className="col-xxl-5 col-lg-7 col-md-12 col-sm-12 py-5 h-100">
              <div className="content">
                <div className="div-image">
                  <a href="/">
                    <img
                      src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                      className="logo-image-class"
                      alt="company logo"
                    />
                  </a>
                </div>
                <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                  <div className="card-title">
                    <h2 className="p-3 pb-1">Sign in</h2>
                  </div>
                  <div className="card-body">
                    <form
                      className={`needs-validation ${
                        validated ? "was-validated" : ""
                      }`}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      {/* <form onSubmit={handleSubmit} className="was-validated"> */}
                      <div className="mb-4">
                        <label
                          htmlFor="username"
                          className="form-label login-form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control login-form-input"
                          id="username"
                          required
                        />
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                      </div>
                      <div className="mb-4 password-field">
                        <div className="pass-forgot">
                          <div>
                            <label
                              htmlFor="password"
                              className="form-label login-form-label"
                            >
                              Password
                            </label>
                          </div>
                          <div className="forgot-text">
                            <Link to="/reset-password">Forgot Password?</Link>
                          </div>
                        </div>
                        <input
                          type={passwordType}
                          className="form-control login-form-input"
                          id="password"
                          required
                        />
                        {passwordType === "password" ? (
                          <ShowPasswordIcon
                            onClick={togglePasswordType}
                            className="eye-icon eye-login"
                          />
                        ) : (
                          <HidePasswordIcon
                            onClick={togglePasswordType}
                            className="eye-icon eye-login"
                          />
                        )}
                      </div>

                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn text-light main-bg btn-style"
                          disabled={loading}
                        >
                          <b>Login</b>
                        </button>
                        <p className="text-center my-4 bottom-text">
                          Don't have an account?{" "}
                          <Link to="/register">Sign Up</Link>
                        </p>
                      </div>
                      {!loginAttempt && (
                        <div style={{ paddingTop: "15px" }}>
                          <Alert variant="danger">
                            <p>Invalid Username or Password</p>
                          </Alert>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
