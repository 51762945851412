import React, { useEffect, useState, useRef } from "react";
import { formatDate } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { getConcussionForms } from "../../actions/patientActions";
import ListHeading from "../list-record-components/list-header/listHeading";
import Table from "../react-table-component/ReactTable";
import { Link } from "react-router-dom";
import historyIcon from "../../assets/img/svg/history-icon.svg";
import editIcon from "../../assets/img/svg/edit-icon.svg";
import dotMenuIcon from "../../assets/img/svg/dots-menu-icon.svg";
import trashIcon from "../../assets/img/svg/trash-can-2.svg";
import { ConcussionFormDeleteConfirmModal } from "./ConcussionFormDeleteConfirmModal";
import { DropDownItem } from "../header/Header";
import "./concussionForms.css";

const genderMap = {
  M: "Male",
  F: "Female",
};
const formCategory = {
  first_visit: "First Visit",
  followup: "Follow Up",
};

export default function ConcussionFormsTable() {
  const dispatch = useDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [id, setId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const token = useSelector((state) => state.loginData.token);
  const formsList = useSelector((state) => state.concussionForms);
  const [progress, setProgress] = useState(0);
  const { loading, concussionForms } = formsList;

  useEffect(() => {
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 20
        );
      }, 500);
      return () => clearInterval(interval);
    };

    simulateProgress();

    dispatch(getConcussionForms(token))
      .then(() => {
        setProgress(100);
      })
      .catch(() => {
        setProgress(100);
      });
  }, [dispatch, token]);

  const [searchField, setSearchField] = useState("");
  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  const filteredForms = React.useMemo(() => {
    return (
      (concussionForms &&
        concussionForms.filter((item) => {
          const patientId = item.id_number.toLowerCase();
          const searchValue = searchField.toLowerCase();
          return patientId.includes(searchValue);
        })) ||
      []
    );
  }, [concussionForms, searchField]);

  const handleSelectedRowsChange = (selectedIds) => {
    setSelectedRows(selectedIds);
  };

  const dotMenuRef = useRef(null);
  const [openMenuRowIndex, setOpenMenuRowIndex] = useState(null);
  const toggleMenuForRow = (rowIndex) => {
    setOpenMenuRowIndex((prevRowIndex) =>
      prevRowIndex === rowIndex ? null : rowIndex
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dotMenuRef.current &&
        !dotMenuRef.current.contains(event.target) &&
        openMenuRowIndex !== null
      ) {
        setOpenMenuRowIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenuRowIndex]);

  const columns = React.useMemo(() => [
    {
      Header: "Patient ID",
      accessor: "id_number",
    },
    {
      Header: "Date of Birth",
      accessor: "date_of_birth",
      Cell: ({ row }) => {
        return <div>{row?.original?.date_of_birth ?? "N/A"}</div>;
      },
    },
    {
      Header: "Gender",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.gender ? genderMap[row.original.gender] : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Date of Test",
      accessor: "date_of_test",
      Cell: ({ row }) => {
        return (
          <div>
            {" "}
            {row.original.date_of_test
              ? formatDate(row.original.date_of_test)
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Visit Type",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.form_category
              ? formCategory[row.original.form_category]
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Score",
      accessor: "symptom_severity_score",
    },
    {
      Header: "",
      accessor: "action",
      disableSortBy: true,
      Cell: ({ row }) => {
        const isMenuOpen = openMenuRowIndex === row.index;
        return (
          <div className="dot-menu-icon">
            <img
              src={dotMenuIcon}
              alt="dot menu"
              onClick={() => toggleMenuForRow(row.index)}
            />

            {isMenuOpen && (
              <div ref={dotMenuRef} className="patients-dot-menu">
                <DropDownItem
                  item={{
                    title: "View",
                    icon: historyIcon,
                    to: `/concussion-form/${row.original.id}`,
                  }}
                />
                <DropDownItem
                  item={{
                    title: "Edit",
                    icon: editIcon,
                    to: `/concussion-forms/1/${row.original.id}`,
                  }}
                />
                <div className="delete-clr">
                  <DropDownItem
                    item={{
                      title: "Delete",
                      icon: trashIcon,
                    }}
                    handleClick={() => {
                      setId(row.original.id);
                      setShowDeleteDialog(true);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <section className="patient-list">
        <ConcussionFormDeleteConfirmModal
          show={showDeleteDialog}
          handleClose={() => {
            setShowDeleteDialog(false);
          }}
          id={id}
          token={token}
        />
        <ListHeading title="Concussion Forms" handleSearch={handleSearch} />
        <div className="newForm">
          <Link to="/concussion-forms/1" className="link">
            {" "}
            + New Form
          </Link>
        </div>

        <div className="ms-2">
          {selectedRows?.length > 0 ? (
            <p>{selectedRows?.length} rows selected</p>
          ) : (
            ""
          )}
        </div>

        <Table
          columns={columns}
          data={filteredForms ?? []}
          // params={searchField}
          // fetchData={fetchData}
          // pageCount={ 1}
          loading={loading}
          tableOf="ConcussionForms"
          onSelectedRowsChange={handleSelectedRowsChange}
          progress={progress}
        />
      </section>
    </>
  );
}
