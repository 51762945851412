// import "../../assets/css/style2.css";
import React, { useEffect, useState } from "react";
import { formatDate, formatTime, formatDOB } from "../../helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { listPatientRecentVisits } from "../../actions/patientActions";
import ListHeading from "../list-record-components/list-header/listHeading";
import Table from "../react-table-component/ReactTable";
import { ArrowDownward, ArrowForwardIos } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import { PatientVisitDeleteConfirmModal } from "./PatientVisitDeleteConfirmModal";

const SETTINGS = {
  1: "Clinic",
  2: "ED",
  3: "ICU",
};

const KEY_NOTES = {
  1: "Baseline",
  2: "Follow-up",
  3: "Normal",
  4: "PDR",
  5: "SSVEP",
  6: "P300",
  7: "Ictal",
  8: "Pre-ictal",
  9: "Sine Wave",
  10: "Square Wave",
  11: "Triangle Wave",
  12: "Test Dataset",
  13: "OMIT",
};

export default function PatientVisits({ category }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isResearchDoctor, setIsResearchDoctor] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const token = useSelector((state) => state.loginData.token);
  const visitsList = useSelector((state) => state.patientsRecentVisitsList);
  const { loading, visits } = visitsList;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (token) {
      const data = token.split(".")[1];
      const parsedData = JSON.parse(atob(data));
      setIsResearchDoctor(!!parsedData.data[0].is_research_doctor);
    }
  }, [token]);

  useEffect(() => {
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 5
        );
      }, 500);
      return () => clearInterval(interval);
    };

    simulateProgress();

    dispatch(listPatientRecentVisits(token))
      .then(() => {
        setProgress(100);
      })
      .catch(() => {
        setProgress(100);
      });
  }, [dispatch, token]);

  // Delete logic
  const handleDelete = () => {
    if (selectedRows.length == 0) {
      console.log("delete : ", selectedRows.length);
      toast.error("Please select at least one visit to delete.");
      return;
    }
    if (selectedRows.length > 1) {
      toast.error("You can't delete more than 1 record at a time.");
      return;
    }
    setShowDeleteDialog(true);
  };

  const [searchField, setSearchField] = useState("");

  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  // const fetchData = useCallback(
  //   ({ pageSize, pageIndex, params, sortBy }) => {
  //     const queryParams = {
  //       page: pageIndex + 1,
  //       // sortBy: sortBy[0]?.id || "createdAt",
  //       // sortOrder: sortBy[0]?.desc === false ? "asc" : "desc",
  //       // search: params,
  //     };
  //     dispatch(listPatientRecentVisits(token, queryParams));
  //   },
  //   [dispatch, token]
  // );

  // Handle selected row changes (from the table component)
  const handleSelectedRowsChange = (selectedIds) => {
    console.log("selectedIds :: ", selectedIds);
    setSelectedRows(selectedIds);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowIndex)) {
        // Remove the row index if it's already expanded
        const newExpandedRows = prevExpandedRows.filter(
          (index) => index !== rowIndex
        );

        return newExpandedRows;
      } else {
        // Add the row index if it's not expanded
        const newExpandedRows = [...prevExpandedRows, rowIndex];
        return newExpandedRows;
      }
    });
  };

  const columns = React.useMemo(() => {
    let baseColumns = [
      {
        Header: "Date of Visit",
        accessor: "timestamp",
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div
              className="expansion-wrap"
              onClick={(e) => {
                toggleRowExpansion(row.index);
              }}
              style={{ cursor: "pointer" }}
            >
              {isExpanded ? (
                <ArrowDownward style={{ fontSize: 12, marginRight: 4 }} />
              ) : (
                <ArrowForwardIos style={{ fontSize: 12, marginRight: 4 }} />
              )}
              {formatDate(row.original.timestamp)}
            </div>
          );
        },
      },
      {
        Header: "ID Number",
        accessor: "patient?.id_number",
        Cell: ({ row }) => {
          return <div>{row?.original?.patient?.id_number}</div>;
        },
      },
      // {
      //   Header: "category",
      //   accessor: "patient.category",
      // },
      {
        Header: "DOB",
        accessor: "patient.dob",
        Cell: ({ row }) => {
          return <div>{formatDOB(row?.original?.patient?.dob)}</div>;
        },
      },
      {
        Header: "Time",
        sortable: true,
        accessor: "time",
        Cell: ({ row }) => {
          return <div>{formatTime(row.values.timestamp)}</div>;
        },
      },
      {
        Header: "Setting",
        accessor: "patient.clinical_setting",
        Cell: ({ row }) => {
          return <div>{SETTINGS[row.original.patient.clinical_setting]}</div>;
        },
      },
      {
        Header: "Key Notes",
        accessor: "patient.clinical_keynote",
        Cell: ({ row }) => {
          return <div>{KEY_NOTES[row.original.patient.clinical_keynote]}</div>;
        },
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div>
              {isExpanded ? (
                <Link
                  to={`/recording/${row?.original?.details[0]?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="action-button-expanded">
                    {" "}
                    VIEW VISIT
                  </button>
                </Link>
              ) : (
                <Link
                  to={`/recording/${row?.original?.details[0]?.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="action-button"> VIEW VISIT</button>
                </Link>
              )}
            </div>
          );
        },
      },
    ];
    if (category === "Clinic") {
      baseColumns.splice(1, 0, {
        Header: "Name",
        accessor: "patient.name",
        Cell: ({ row }) => <div>{row.original.patient.name ?? "N/A"}</div>,
      });
    }
    return baseColumns;
  }, [expandedRows, toggleRowExpansion]);

  const filteredVisits = React.useMemo(() => {
    if (!Array.isArray(visits?.data)) return [];

    const lowerCategory = category?.toLowerCase() || "";
    const searchValue = searchField?.toLowerCase() || "";

    const categoryFilteredVisits = visits.data.filter((visit) => {
      const patient_category = visit?.patient?.category
        ? visit.patient.category.toLowerCase()
        : "";
      return patient_category.includes(lowerCategory);
    });

    return categoryFilteredVisits.filter((visit) => {
      const patientName = visit?.patient?.name
        ? visit.patient.name.toLowerCase()
        : "";
      const id_number = visit?.patient?.id_number
        ? visit?.patient?.id_number.toLowerCase()
        : "";
      const dob = visit?.patient?.dob ? formatDOB(visit.patient.dob) : "";
      const mrn = visit?.patient?.id_number
        ? visit.patient.id_number.toString()
        : "";

      return (
        patientName.includes(searchValue) ||
        id_number.includes(searchValue) ||
        dob.includes(searchValue) ||
        mrn.includes(searchValue)
      );
    });
  }, [visits?.data, searchField, category]);

  const isCompareButtonDisabled =
    selectedRows.length && selectedRows.length !== 2;

  const handleCompareVisits = () => {
    if (selectedRows.length === 2) {
      const queryString = selectedRows.map((id) => `id=${id}`).join("&");
      history.push(`/compare-visits?${queryString}`);
    } else {
      toast.error("Please select exactly 2 visits to compare.");
    }
  };

  return (
    <>
      <PatientVisitDeleteConfirmModal
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false);
        }}
        id={selectedRows[0]}
        token={token}
      />
      <section className="patient-list">
        <ListHeading title="Recent Visits" handleSearch={handleSearch} />
        {/* <div>
          <button
            className="button btn-default rounded-pill"
            onClick={handleCompareVisits}
            disabled={isCompareButtonDisabled}
          >
            COMPARE VISITS
          </button>
          <button
            className="button btn-default rounded-pill"
            onClick={handleDelete}
          >
            DELETE
          </button>
        </div> */}
        <div className="ms-2">
          {selectedRows?.length > 0 ? (
            <p>{selectedRows?.length} rows selected</p>
          ) : (
            ""
          )}
        </div>

        <Table
          columns={columns}
          data={filteredVisits ?? []}
          // params={searchField}
          // fetchData={fetchData}
          // pageCount={ 1}
          loading={loading}
          tableOf="PatientVisits"
          onSelectedRowsChange={handleSelectedRowsChange}
          progress={progress}
        />
      </section>
    </>
  );
}
