import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  past_concussions_count: Yup.number()
    .nullable()
    .typeError("Past Diagnosed must be a number"),
});

const ConcussionHistory = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const token = useSelector((state) => state.loginData.token);
  const { concussionForm = [] } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error! " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id]);

  const defaultValues = {
    past_concussions_count: null,
    most_recent_concussion_date: null,
    recovery_time: "",
    primary_symptoms: "",
    notes: "",
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm2"));

  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      dispatch(updateConcussionForm(token, id, values))
        .then(() => {
          if (save) {
            history.push(`/concussion-forms`);
            toast.success("Concussion Form Updated Successfully");
          } else {
            history.push(`/concussion-forms/3/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error! " + error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      localStorage.setItem("ConcussionForm2", JSON.stringify(values));
      history.push("/concussion-forms/3");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Concussion History</span>
        <span className="form-progress">2/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  How many past diagnosed concussion?
                </label>
                <Field name="past_concussions_count" className="field-input" />
                <ErrorMessage
                  name="past_concussions_count"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Most recent concussion?</label>
                <Field
                  name="most_recent_concussion_date"
                  className="field-input"
                  type="date"
                />
                <ErrorMessage
                  name="most_recent_concussion_date"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Recovery time from most recent concussion?
                </label>
                <Field
                  name="recovery_time"
                  className="field-input"
                  placeholder="2 weeks"
                />
                <ErrorMessage
                  name="recovery_time"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Primary symptoms?</label>
                <Field
                  name="primary_symptoms"
                  as="textarea"
                  className="field-input symptoms"
                />
                <ErrorMessage
                  name="primary_symptoms"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="notes"
                  as="textarea"
                  className="field-input symptoms"
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/1/${id}` : `/concussion-forms/1`
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConcussionHistory;
