import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../../actions/patientActions";
import { toast } from "react-toastify";
import { updateScore } from "../../../../helper";

const CurrentInjury3 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    headache: 0,
    head_pressure: 0,
    neck_pain_score: 0,
    nausea: 0,
    dizziness: 0,
    vision_problems: 0,
    balance_problems: 0,
    sensitivity_to_light: 0,
  };

  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm9"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    if (id) {
      const { totalSymptoms, severityScore } = updateScore(values);

      dispatch(
        updateConcussionForm(token, id, {
          ...values,
          total_symptoms: totalSymptoms,
          symptom_severity_score: severityScore,
        })
      )
        .then(() => {
          if (save) {
            toast.success("Concussion Form Updated Successfully");
            history.push(`/concussion-forms`);
          } else {
            history.push(`/concussion-forms/10/${id}`);
          }
        })
        .catch((error) => {
          toast.error("Error!" + error.message);
        })
        .finally(() => setSubmitting(false));
    } else {
      localStorage.setItem("ConcussionForm9", JSON.stringify(values));
      history.push("/concussion-forms/10");
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">9/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Please rate the symptoms you have today on a scale from 1 to 6
                  where 1 is the best and 6 is the worst:
                </label>
              </div>

              <div className="field-wrapper">
                <label className="field-label">Headaches</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="headache">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("headache", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="headache"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Pressure in head</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="head_pressure">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("head_pressure", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="head_pressure"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Neck pain</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="neck_pain_score">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("neck_pain_score", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="neck_pain_score"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Nausea or vomiting </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="nausea">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("nausea", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="nausea"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Dizziness </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="dizziness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("dizziness", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="dizziness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Blurred vision </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="vision_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("vision_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="vision_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Balance problems </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="balance_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("balance_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="balance_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Sensitivity to light </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="sensitivity_to_light">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("sensitivity_to_light", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="sensitivity_to_light"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(
                    id ? `/concussion-forms/8/${id}` : "/concussion-forms/8"
                  )
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury3;
